import React from 'react';
import { withDataProvider, DELETE } from 'react-admin';
import Button from '@material-ui/core/Button';

class CancelRunButtonView extends React.Component {
    handleClick = () => {
        const { dataProvider } = this.props;
        const record = this.props.data || this.props.record
        dataProvider(DELETE, 'scheduling', { id: record.latestRunId || record.id }, {
            onSuccess: {
                notification: { body: 'Run cancelled', level: 'info' },
                refresh: true
            },
            onFailure: {
                notification: { body: 'Error: could not cancel run', level: 'warning' }
            }
        })
    }

    render() {
        const record = this.props.data || this.props.record;
        return record && ((record.statusTypeId >= 0 && record.statusTypeId <= 2) || record.latestRunIsPastEntrypoint === false) ? <Button variant="flat" onClick={this.handleClick}>Cancel run</Button>
            : null;
    }
}

export const CancelRunButton = withDataProvider(CancelRunButtonView)